//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
//import { getEntityTableColumns } from "@/utils/enums";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  components: {
    DataTable,
    CategoryPath
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    routeParams: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    tableLoader({
      query
    }) {
      return this.$store.dispatch("listM/getListMCategories", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    fileName() {
      return `${this.routeParams.mp.toUpperCase()} 列表 ${this.list.title} ${this.reportSettings.date} ${this.reportSettings.date2}`;
    },
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 2;
      return [{
        title: "类别",
        position: 1,
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      },
      ////...getEntityTableColumns("category", 2),

      ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};