/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./BrandsNewSellersDetailTable.vue?vue&type=template&id=1c89584f&"
import script from "./BrandsNewSellersDetailTable.vue?vue&type=script&lang=js&"
export * from "./BrandsNewSellersDetailTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports